import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InMemoryCache } from '@apollo/client/core';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@spotlight/core';
import { AppConfig } from '@spotlight/core';
import {
  APOLLO_FLAGS,
  APOLLO_OPTIONS,
  ApolloModule
} from 'apollo-angular';
import {
  NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import './prototyps';
import { SfdcComponent } from './sfdc/sfdc.component';
import { InlineStylesCSPModule } from './inline-styles-csp/inline-styles-csp.module';

import { HttpLink } from 'apollo-angular/http';

export const GA_KEY = new InjectionToken<string>('ga_key');

class IGoogleAnalyticsSettings {}

export function getGraphURI(config: AppConfig, httpLink: HttpLink) {
  return {
    cache: new InMemoryCache(),
    link: httpLink.create({
      uri: `${config.api}graphql-discovery`
    }),
  };
}

export function getTrackCode(config: AppConfig): IGoogleAnalyticsSettings {
  return {
    trackingCode: config.gaKey,
  } as IGoogleAnalyticsSettings;
}

@NgModule({
  declarations: [AppComponent, SfdcComponent],
  exports: [CoreModule],
  imports: [
    ApolloModule,
    BrowserModule,
    CommonModule,
    InlineStylesCSPModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useFactory: getTrackCode,
      deps: [AppConfig],
    },
    NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true, // enable it here
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: getGraphURI,
      deps: [AppConfig, HttpLink],
    },
  ],
})
export class AppModule {}
