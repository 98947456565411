/* eslint-disable security/detect-object-injection */
Array.prototype.groupBy = groupBy;
Array.prototype.equals = equals;
Array.prototype.remove = remove;

interface Array<T> {
  groupBy: typeof groupBy;
  equals: typeof equals;
  remove: typeof remove;
}

function groupBy<T, K extends keyof any>(key: K): T[] {
  return (this).reduce((acc: any[], current: any) => {
      (acc[current[key]] = acc[current[key]] || []).push(current); // nosemgrep
      return acc;
    }, []
  ).filter(f => !!f);
}

function equals(array: Array<any>): boolean {
  if (!array) {
    return false;
  }

  if (this.length !== array.length) {
    return false;
  }

  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {// nosemgrep
      if (!this[i].equals(array[i])) {// nosemgrep
        return false;
      }
    } else if (this[i] !== array[i]) {// nosemgrep
      return false;
    }
  }
  return true;
}

function remove(valueToRemove: any): any[] {
  if (this.includes(valueToRemove)) {
    return this.splice(this.indexOf(valueToRemove), 1);// nosemgrep
  }

  return this;
}
