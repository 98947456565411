<h1 mat-dialog-title>
  <span>{{ data.title ? data.title : 'Delete' }}</span>
</h1>

<div mat-dialog-content>{{data.message}}</div>

<mat-dialog-actions>
  <div class="spai-button-block">
    <button mat-dialog-close spai-button-outline>
      {{data.cancelTitle ? data.cancelTitle : 'No'}}
    </button>

    <button (click)="confirmClick()" spai-button-solid>
      {{data.acceptTitle ? data.acceptTitle : 'Confirm'}}
    </button>
  </div>
</mat-dialog-actions>
