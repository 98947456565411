import { CommonModule } from '@angular/common';
import {
  Component,
  Input
} from '@angular/core';
import { LegacyProgressBarMode as ProgressBarMode } from '@angular/material/legacy-progress-bar';
import { MaterialModule } from '@shared/material.module';

@Component({
  selector: 'spai-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class ProgressBarComponent {
  @Input()
  value?: number = 0;

  @Input()
  displayValue?: boolean;

  @Input()
  isBlocked?: boolean;

  mode?: ProgressBarMode = 'determinate';

  get barColor(): object {
    if (this.isBlocked) {
      return { red: this.isBlocked };
    }

    return {
      grey: this.value >= 0 && this.value <= 14,
      light: this.value >= 15 && this.value <= 49,
      yellow: this.value >= 50 && this.value <= 99,
      green: this.value === 100
    };
  };
}
