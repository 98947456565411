import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'spai-confirm-notification',
  templateUrl: './confirm-notification.component.html',
  styleUrls: ['./confirm-notification.component.scss'],
})
export class ConfirmNotificationComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActionNotificationConfig
  ) {}

  confirmClick(): void {
    this.dialogRef.close(true);
  }
}

export class ActionNotificationConfig {
  title?: string;
  acceptTitle?: string;
  cancelTitle?: string;
  message: string;
}
