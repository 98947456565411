import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SfdcComponent } from './sfdc/sfdc.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./+login/login.module').then(m => m.LoginModule),
    data: { title: 'Login' },
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./+subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    data: { title: 'Subscriptions Management' },
  },
  {
    path: '',
    loadChildren: () => import('./+layout/layout.module').then(m => m.LayoutModule),
  },
  {
    path: 'sfdc',
    component: SfdcComponent,
  },
  {
    path: '**',
    redirectTo: 'pipeline',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 160],
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
