import {
  CommonModule,
  CurrencyPipe,
  DatePipe
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ByteFormat } from '@shared/components/file-input/byte-format.pipe';
import { FileInputItem } from '@shared/components/file-input/file-input-item';
import { DropzoneDirective } from '@shared/directives/dropZone.directive';
import { NumberOnlyDirective } from '@shared/directives/number-only.directive';
import { ForbiddenValuesDirective } from '@shared/directives/validation/forbidden-values.directive';
import { NotificationService } from '@shared/services/notification.service';
import { NgbDateCustomParserFormatter } from '@spotlight/core';
import {
  SpaiButtonComponentModule,
  SpaiPasswordModule,
  SpaiSelectModule
} from '@spotlight/shared';
import { RecaptchaModule } from 'ng-recaptcha';
import { DndModule } from 'ngx-drag-drop';

import { AvatarUploadComponent } from './components/avatar-upload/avatar-upload.component';
import { CalloutComponent } from './components/callout/callout.component';
import { CapsulesComponent } from './components/capsules/capsules.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmNotificationComponent } from './components/confirm-notification/confirm-notification.component';
import { ContentPlaceholderComponent } from './components/content-placeholder/content-placeholder.component';
import { DatepickerPopupComponent } from './components/datepicker-popup/datepicker-popup.component';
import { DatepickerRangePopupComponent } from './components/datepicker-range-popup/datepicker-range-popup.component';
import { DealFiltersComponent } from './components/deal-fiters/deal-filters.component';
import { ExplainerComponent } from './components/explainers/explainer/explainer.component';
import { ExplainersComponent } from './components/explainers/explainers.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FormFieldSearchComponent } from './components/form-field-search/form-field-search.component';
import { ImagePreviewUploadComponent } from './components/image-preview-upload/image-preview-upload.component';
import { LoadingComponent } from './components/loading-data/loading-data.component';
import { MeddiccLetterComponent } from './components/meddicc-section/meddicc-letter/meddicc-letter.component';
import { MeddiccSectionComponent } from './components/meddicc-section/meddicc-section.component';
import { CustomFiltersFormModalComponent } from './components/modal/custom-filters-form-modal/custom-filters-form-modal.component';
import { EditCustomFiltersFormModalComponent } from './components/modal/edit-custom-filters-form-modal/edit-custom-filters-form-modal.component';
import { MeddiccModalComponent } from './components/modal/meddicc-modal/meddicc-modal.component';
import { NewOpportunityModalComponent } from './components/modal/new-opportunity-modal/new-opportunity-modal.component';
import { NewUserModalComponent } from './components/modal/new-user-modal/new-user-modal.component';
import { ServiceUnavailableModalComponent } from './components/modal/service-unavailable-modal/service-unavailable-modal.component';
import { SlidePreviewModalComponent } from './components/modal/slide-preview-modal/slide-preview-modal.component';
import { OptionPanelComponent } from './components/option-panel/option-panel.component';
import { OptionsModalComponent } from './components/options-modal/options-modal.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SliderComponent } from './components/slider/slider.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TaskbarActionComponent } from './components/taskbar-action/taskbar-action.component';
import { ToastsContainerComponent } from './components/toasts-container/toasts-container.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { DefaultImageDirective } from './directives/default-image.directive';
import { DragNDropDirective } from './directives/drag-n-drop.directive';
import { DraggableCursorsDirective } from './directives/draggable-cursors.directive';
import { FormFocusDirective } from './directives/form-focus.directive';
import { LoadingDirective } from './directives/loading.directive';
import { NumberFormatDirective2 } from './directives/number-format-2.directive';
import { NumberFormatDirective } from './directives/number-format.directive';
import { ScrollableDirective } from './directives/scrollable.directive';
import { VisibilityDirective } from './directives/visibility.directive';
import { MaterialModule } from './material.module';
import { FilePreviewModule } from './modules/file-preview/file-preview.module';
import { SpaiFormsModule } from './modules/forms/forms.module';
import { GaugesModule } from './modules/gauges/gauges.module';
import { IconModule } from './modules/icon/icon.module';
import { RadioButtonModule } from './modules/radio-button/radio-button.module';
import { CostSummaryPipe } from './pipes/cost-summary.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';

import { SanitizeHtmlPipe } from './pipes/sanitizeHtml.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { MeetingTranscriptionsComponent } from './components/meeting-transcriptions/meeting-transcriptions.component';
import { ParagraphComponent } from './components/paragraph/paragraph.component';
import { HighlightMatchesDirective } from './directives/highlight-matches.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    RecaptchaModule,
    DndModule,
    MaterialModule,
    IconModule,
    AngularEditorModule,
    GaugesModule,
    SpaiFormsModule,
    SpaiPasswordModule,
    MeddiccLetterComponent,
    ProgressBarComponent,
    SpaiButtonComponentModule,
    RadioButtonModule,
    TaskbarActionComponent,
    MeddiccSectionComponent,
    FormFieldSearchComponent,
    SpaiSelectModule
  ],
  exports: [
    // Directives
    LoadingDirective,
    DatepickerPopupComponent,
    VisibilityDirective,
    AutofocusDirective,
    FileInputComponent,
    DropzoneDirective,
    NumberFormatDirective,
    NumberOnlyDirective,
    ScrollableDirective,
    DefaultImageDirective,
    DraggableCursorsDirective,
    FilePreviewModule,
    NumberFormatDirective2,
    DragNDropDirective,
    //Pipes
    ShortNumberPipe,
    SortByPipe,
    SanitizeHtmlPipe,
    CostSummaryPipe,
    FormatNumberPipe,

    // Components
    SpaiButtonComponentModule,
    AvatarUploadComponent,
    ImagePreviewUploadComponent,
    CalloutComponent,
    CheckboxComponent,
    LoadingComponent,
    ToastsContainerComponent,
    CapsulesComponent,
    UserAvatarComponent,
    SwitchComponent,
    DatepickerRangePopupComponent,
    ExplainerComponent,
    ExplainersComponent,
    DealFiltersComponent,
    SpinnerComponent,
    SliderComponent,
    ContentPlaceholderComponent,
    OptionPanelComponent,

    // Modules
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    GaugesModule,
    NgSelectModule,
    MeetingTranscriptionsComponent,
    ParagraphComponent
  ],
  declarations: [
    // Directives
    LoadingDirective,
    DragNDropDirective,
    DropzoneDirective,
    AutofocusDirective,
    VisibilityDirective,
    NumberOnlyDirective,
    NumberFormatDirective,
    NumberFormatDirective2,
    DraggableCursorsDirective,
    DefaultImageDirective,
    CostSummaryPipe,
    ScrollableDirective,

    //Pipes
    ByteFormat,
    ShortNumberPipe,
    SortByPipe,
    SanitizeHtmlPipe,
    FormatNumberPipe,
    // Components
    FileInputItem,
    CheckboxComponent,
    CalloutComponent,
    LoadingComponent,
    ToastsContainerComponent,
    DatepickerPopupComponent,
    AvatarUploadComponent,
    ImagePreviewUploadComponent,
    ServiceUnavailableModalComponent,
    NewUserModalComponent,
    NewOpportunityModalComponent,
    DatepickerRangePopupComponent,
    CapsulesComponent,
    UserAvatarComponent,
    SwitchComponent,
    ConfirmNotificationComponent,
    ExplainerComponent,
    ExplainersComponent,
    FileInputComponent,
    NumberFormatDirective,
    DealFiltersComponent,
    CustomFiltersFormModalComponent,
    EditCustomFiltersFormModalComponent,
    ContentPlaceholderComponent,
    SpinnerComponent,
    SliderComponent,
    DefaultImageDirective,
    CostSummaryPipe,
    OptionsModalComponent,
    SlidePreviewModalComponent,
    MeddiccModalComponent,
    OptionPanelComponent,
    FormFocusDirective,
    ForbiddenValuesDirective,
    MeetingTranscriptionsComponent,
    ParagraphComponent,
    HighlightMatchesDirective,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    NotificationService,
    DatePipe,
    CurrencyPipe
  ],
})
export class SharedModule {}
