import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon/icon.module';
import { ControlErrorComponent } from './control-error/control-error.component';
import { InputContainerComponent } from './input-container/input-container.component';
import { InputDirective } from './input.component';
import { LabelDirective } from './label.directive';
import { RadioContainerComponent } from './radio/radio-container.component';
import { RadioComponent } from './radio/radio.component';



@NgModule({
  declarations: [
    InputDirective,
    InputContainerComponent,
    LabelDirective,
    ControlErrorComponent,
    RadioComponent,
    RadioContainerComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports:[
    InputDirective,
    InputContainerComponent,
    LabelDirective,
    ControlErrorComponent,
    RadioComponent,
    RadioContainerComponent
  ]
})
export class SpaiFormsModule { }
