import { CommonModule } from '@angular/common';
import {
  Component,
  Input
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import {
  SettingsService,
  TaskbarAction
} from '@spotlight/core';

import { MeddiccLetterComponent } from '../meddicc-section/meddicc-letter/meddicc-letter.component';

@Component({
  selector: 'spai-taskbar-action',
  templateUrl: './taskbar-action.component.html',
  styleUrls: ['./taskbar-action.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, MatCheckboxModule, MeddiccLetterComponent, NgbPopoverModule]
})
export class TaskbarActionComponent {
  @Input()
  action: TaskbarAction;

  constructor(private settingsService: SettingsService) {}

  get actionEditAllowed(): boolean {
    return this.settingsService.settings?.actionEdit;
  }

  get isBlocking(): boolean {
    return this.action?.importance === 'Blocking';
  }

  editNote(input: HTMLInputElement) {
    if (!this.actionEditAllowed) {
      return;
    }

    input.classList.add('visible');
    input.focus();
  }

  focusOut(note: string, noteInput: HTMLInputElement): void {
    if (!note) {
      noteInput.classList.remove('visible');
    }
  }
}
