import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FilePreviewOverlayToolbarComponent } from './components/file-preview-overlay-toolbar/file-preview-overlay-toolbar.component';
import { FilePreviewOverlayComponent } from './file-preview-overlay.component';
import { FilePreviewOverlayService } from './file-preview-overlay.service';



@NgModule({
  declarations: [
    FilePreviewOverlayToolbarComponent,
    FilePreviewOverlayComponent
  ],
  imports: [
    OverlayModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule
  ],
  providers:[FilePreviewOverlayService]
})
export class FilePreviewModule { }
