<mat-form-field
  appearance="outline"
  class="spai-mat-form-field w-100"
  subscriptSizing="dynamic"
>
  <spai-icon
    matPrefix
    class="search-icon"
    name="search"
    size="md"
    width="24"
    height="24"
  ></spai-icon>

  <input
    #inputElement
    [attr.aria-labelledby]="'Search'"
    [attr.disabled]="disabled"
    [formControl]="formControl"
    [placeholder]="placeholder"
    matInput
    type="text"
    (change)="commit()"
  />
  <button
    (click)="formControl.setValue('')"
    *ngIf="formControl.value"
    aria-label="Clear"
    mat-icon-button
    matSuffix
    class="spai-button clean-button"
  >
    <spai-icon
      name="close"
      width="16"
      height="16"
    ></spai-icon>
  </button>
</mat-form-field>
