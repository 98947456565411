import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import { AppConfig } from '@spotlight/core';
import { RECAPTCHA_SETTINGS } from 'ng-recaptcha';

import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

fetch(`assets/ui-config.json?t=${new Date().getTime()}`)
  .then(data => data.json())
  .then(appConfig => {
    platformBrowserDynamic([
      {
        provide: AppConfig,
        useValue: appConfig,
      },
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: { siteKey: appConfig.recaptchaSiteKey },
      },
    ]).bootstrapModule(AppModule);
  });
