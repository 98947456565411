import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IconModule } from '@spotlight/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'spai-form-field-search',
  templateUrl: './form-field-search.component.html',
  styleUrls: ['./form-field-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldSearchComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IconModule, MatFormFieldModule, MatInputModule, MatIconModule],
  host: {
    '(focus)': 'focus()',
    'role': 'search',
    '[attr.tabindex]': '1'
  }
})
export class FormFieldSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef<HTMLInputElement>;

  @Input()
  placeholder = '';

  @Input()
  disabled: boolean;

  /**
   * Fired at controls when the value change
   */
  @Output()
  valueChange = new EventEmitter<string>();

  /**
   * Fired at controls when the user commits a value change.
   * Triger on input change event
   */
  @Output()
  commitChange = new EventEmitter<string>();

  formControl = new UntypedFormControl('');
  focused = false;

  private unsubscribe$ = new Subject<void>();

  protected controlValueAccessorChangeFn: (value: any) => void = () => {};
  protected controlValueAccessorTouchedFn: (value: any) => void = () => {};

  writeValue(value: any): void {
    this.formControl.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.controlValueAccessorChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.controlValueAccessorTouchedFn = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.controlValueAccessorChangeFn(value);
      this.valueChange.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  blurInput(): void {
    this.focused = false;
  }

  focus(): void {
    this.inputElement?.nativeElement?.focus();
    this.focusInput();
  }

  focusInput(): void {
    this.focused = true;
  }

  clear(): void {
    this.formControl.setValue('');
  }

  commit() {
    this.commitChange.emit(this.formControl.value);
  }
}
