import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'spai-meddicc-letter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './meddicc-letter.component.html',
  styleUrls: ['./meddicc-letter.component.scss'],
})
export class MeddiccLetterComponent {
  @Input()
  letter?: string;

  @Input()
  size: 'sm'| 'md' | 'lg' | 'xl' = 'md';

  @Input()
  readOnly?: boolean;

  @Input()
  color?: string;

  get classes(): object {
    return {
      [this.size]: this.size,
      [this.color]: this.color?.toLowerCase()
    }
  };
}
