import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  ActionNotificationConfig,
  ConfirmNotificationComponent
} from '@shared/components/confirm-notification/confirm-notification.component';

@Injectable()
export class NotificationService {
  constructor(private dialog: MatDialog) {}

  actionConfirm(data: ActionNotificationConfig): MatDialogRef<ConfirmNotificationComponent> {
    return this.dialog.open(ConfirmNotificationComponent, {// nosemgrep
      data,
      width: '600px',
      backdropClass: 'cdk-overlay-dark-backdrop',
      hasBackdrop: true,
      disableClose: true
    });
  }
}
