import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';
import { AppConfig, BaseLocalStorage } from '@spotlight/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filter, map } from 'rxjs/operators';
import { StompService } from './+opportunity/services/stomp.service';

@Component({
  selector: 'spai-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'spotlight';

  constructor(
    private appConfig: AppConfig,
    private localStorage: BaseLocalStorage,
    private titleService: Title,
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private stompService: StompService
  ) {}

  @HostListener('window:beforeunload', )
  beforeUnloadHandler(): boolean | void {
    try {
      this.stompService?.deactivate();
    } catch (e) {
      console.error(e);
      return null;
    }

    return null;
  }

  @HostListener('window:message', ['$event'])
  messageEvent(event: MessageEvent): void {
    if (event.origin === this.appConfig.salesUiForceUrl) {
      if (event.data) {
        const data = JSON.parse(event.data);
        this.localStorage.setItem(data.key, JSON.stringify(data.value));
      }
    }
  }

  ngOnInit(): void {
    this.gaService.event('Site loaded', 'test test test', 'OnInit');

    this.router.events
      .pipe(
        filter(event => event instanceof RoutesRecognized),
        map((event: RoutesRecognized) => this.getSiteTitle('Spotlight', event.state.root))
      )
      .subscribe((customData: string) => {
        this.titleService.setTitle(customData ? customData : 'Spotlight');
      });
  }

  ngOnDestroy(): void {
    this.gaService.event('Side Closed', 'User close browser or tab', 'OnDestroy');
  }

  getSiteTitle(prevValue: string, snapShot: ActivatedRouteSnapshot): string {
    let tempTitle = prevValue;

    if (snapShot.firstChild?.data.title) {
      tempTitle = `${snapShot.firstChild.data.title} | ${tempTitle}`;
    }
    return snapShot.firstChild ? this.getSiteTitle(tempTitle, snapShot.firstChild) : tempTitle;
  }
}
