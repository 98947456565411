<spai-file-preview-overlay-toolbar>
    <div class="d-flex justify-content-end w-100">
        <button class="spai-button spai-button-icon" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
  </spai-file-preview-overlay-toolbar>

  <div class="overlay-content"
    [@slideContent]="animationState"
    (@slideContent.start)="onAnimationStart($event)"
    (@slideContent.done)="onAnimationDone($event)">
    <div class="spinner-wrapper" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>

    <img [@fade]="loading ? 'fadeOut' : 'fadeIn'" (load)="onLoad()" [src]="image.url">
  </div>
