/* eslint-disable security/detect-object-injection */
// nosemgrep eslint.detect-object-injection
import { Injectable } from '@angular/core';
import { SettingsService, Stomp } from '@spotlight/core';
import { Message } from '@stomp/stompjs';
import { map, Observable, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StompService extends Stomp {
  private dealsEvents$: { [key: string]: Observable<Message> } = {};

  private _unsubscribe$ = new Subject<void>();

  constructor(private settingsService: SettingsService) {
    super();
  }

  listenDealsEvents(dealId: number): Observable<Message> {
    if (!this.dealsEvents$[dealId]) { // nosemgrep
      // nosemgrep
      this.dealsEvents$[dealId] = this.settingsService.settings$.pipe(
        // nosemgrep
        switchMap(() => this.subscribe(`${this.settingsService.settings.topic}/deal/${dealId}/events`)),
        map((message: Message) => message),
        shareReplay(1), // cache the emitted values and replay for future subscribers
        takeUntil(this._unsubscribe$),
      );
    }
    return this.dealsEvents$[dealId]; // nosemgrep
  }

  deactivate(): void {
    this._unsubscribe$.next();
    this.dealsEvents$ = {};
    try {
      super.deactivate();
    } catch (e) {
      console.error(e);
    }
  }
}
