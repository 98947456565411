<div class="progress-wrapper">
  <mat-progress-bar
    [mode]="mode"
    [ngClass]="barColor"
    [value]="value"
  ></mat-progress-bar>
  <div
    *ngIf="displayValue"
    class="value"
  >
    {{ value }}
  </div>
</div>
