import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '@spotlight/core';

@Component({
  selector: 'spai-toasts',
  styleUrls: ['./toasts-container.component.scss'],
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.className"
      [header]="toast.header"
      [animation]="true"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (click)="onToastClick(toast)"
      (hidden)="toast.hideOnClick ? toastService.remove(toast) : null"
    >
      <ng-template *ngIf="true">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template
        [ngIf]="isTemplate(toast)"
        [ngIfElse]="text"
      >
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>

      <ng-template #text1>
        <div *ngIf="toast.customHeader">
          <spai-spinner></spai-spinner>
          <span class="custom-header">{{ toast.customHeader }}</span>
          <hr />
        </div>
        {{ toast.textOrTpl }}
      </ng-template>
    </ngb-toast>
  `,
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3',
    '[style.z-index]': '1050',
  },
})
export class ToastsContainerComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }

  onToastClick(toast): void {
    toast.hideOnClick ? this.toastService.remove(toast) : null;
  }
}
