import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartsModule } from '@carbon/charts-angular';

import { GaugeComponent } from './components/gauge/gauge.component';
import { ScoreComponent } from './components/score/score.component';

@NgModule({
  declarations: [GaugeComponent, ScoreComponent],
  imports: [ChartsModule, CommonModule],
  exports: [GaugeComponent, ScoreComponent],
})
export class GaugesModule {}
