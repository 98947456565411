<div
  (mouseover)="editNote(noteInput)"
  [class.read-only]="!actionEditAllowed"
  class="item"
>
  <mat-checkbox
    [(ngModel)]="action.completed"
    class="spai-mat-checkbox spai-mat-checkbox-sm spai-mat-checkbox-circle spai-mat-checkbox-no-padding"
  ></mat-checkbox>

  <div class="action-name custom-col-1">
    <span [class.fw-bold]="isBlocking"
          class="name">
      {{ action.name }}
      <ng-container
        *ngIf="action.meddicShort"
        class="d-inline-flex"
      >
        <spai-meddicc-letter
          [color]="action?.meddicShort?.color | lowercase"
          [letter]="action?.meddicShort?.letter"
          [ngbPopover]="action?.meddicShort?.name"
          [readOnly]="true"
          class="ms-2 meddicc-letter"
          size="sm"
          triggers="mouseenter:mouseleave"
        ></spai-meddicc-letter>
      </ng-container>
    </span>
    <div
      [hidden]="!actionEditAllowed"
      class="input-wrapper"
    >
      <input
        #noteInput
        (focusout)="focusOut(action.note, noteInput)"
        [(ngModel)]="action.note"
        [class.hidden]="!action.note"
        [class.visible]="action.note"
        [hidden]="!actionEditAllowed"
        class="note"
        placeholder="Notes"
        type="text"
      />
    </div>
  </div>
</div>
