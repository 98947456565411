import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProgressBarComponent } from '@shared/components/progress-bar/progress-bar.component';
import { Meddic } from '@spotlight/core';

import { MeddiccLetterComponent } from './meddicc-letter/meddicc-letter.component';

@Component({
  selector: 'spai-meddicc-section',
  templateUrl: './meddicc-section.component.html',
  styleUrls: ['./meddicc-section.component.scss'],
  standalone: true,
  imports: [CommonModule, MeddiccLetterComponent, ProgressBarComponent]
})
export class MeddiccSectionComponent {
  @Input()
  meddics?: Meddic[];
}
