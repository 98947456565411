<svg
  enable-background="new 0 0 0 0"
  id="L3"
  version="1.1"
  viewBox="-8 -8 120 120"
  x="0px"
  xml:space="preserve"
  xmlns="http://www.w3.org/2000/svg"
  y="0px"
>
  <circle
    cx="50"
    cy="50"
    fill="none"
    r="44"
    stroke="#fff"
    stroke-width="10"
    stroke-opacity="0.5"
  />
  <circle
    cx="8"
    cy="54"
    fill="#fff"
    r="12"
    stroke="#25283D"
    stroke-width="6"
  >
    <animateTransform
      attributeName="transform"
      dur="2s"
      from="0 50 48"
      repeatCount="indefinite"
      to="360 50 52"
      type="rotate"
    />
  </circle>
</svg>
