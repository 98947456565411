import { Component } from '@angular/core';

@Component({
  templateUrl: './sfdc.component.html',
  styleUrls: ['./sfdc.component.scss']
})
export class SfdcComponent {


}
